import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { useState } from 'react';
import { send } from 'emailjs-com';
import Recaptcha from 'react-google-invisible-recaptcha';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const ContactForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const [toSend, setToSend] = useState({
    from_name: '',
    to_name: '',
    message: '',
    reply_to: '',
  });

  const [valid,setValid] = useState(false);

  const refCaptcha = React.createRef();

  const onSubmit = (e) => {
    e.preventDefault();
      console.log(toSend);
      /* --- METHOD TO SEND THE MAIL --- */
      if(valid && toSend.from_name !== "" && toSend.message !== "" && toSend.reply_to !==""){

        send(
          'service_3xwlik7',
          'template_fkp2fae',
          toSend,
          'ugKxRBtc9LyczfEYZ'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert("Thanks You. Your message has been sent")
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      }
      else{
        console.log("Invalid Form");
      }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    refCaptcha.current.execute();
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container flex-container flex-basis">
            <div className="my-form-left" id="contact-us">
              <h3>Would you like to start a project with us?</h3>
              Fill out our contact form and we will be in touch shortly !
              <p></p>
            </div>
            <form onSubmit={onSubmit}>
            <div className="my-form-right">
              <p></p>
              <Input
                name="from_name"
                rows={1}
                value={toSend.from_name}
                onChange={handleChange}
                placeholder={"Your name*"}
                required
                />
              <p></p>
              <Input
                name="reply_to"
                rows={1}
                value={toSend.reply_to}
                onChange={handleChange}
                type={"email"}
                placeholder={"Your email*"}
                required
                />
              <p></p>
              <textarea
                name="message"
                value={toSend.message}
                onChange={handleChange}
                className="form-input"
                rows={5}
                cols={50}
                placeholder={"Your message*"}
                required
              />
              <Recaptcha
                ref={refCaptcha}
                sitekey={"6Lev4mkfAAAAAPjgN_1hv1XEpPL2LcfZZNpZMt3m"}
                onResolved={() =>setValid(true)}
              />
              <p></p>
              <Button className={"button-primary"}>Submit</Button>
              <p></p>
            </div>
          </form>
      </div>
      <script src="https://www.google.com/recaptcha/api.js"></script>
    </section>
  );
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
