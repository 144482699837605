import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Open Source Project',
    paragraph:"Tilak.io has a strong commitment to opensource as we believe it is a key element in taking drone technology to the next level. We have been working on a next generation log viewer for drone data."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} id="opensource">
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  Meet TiPlot !
                  </h3>
                  <p className="m-1">
                    A User friendly interface allow to quickly browse through complex data and display valuable insights out of your flights.<br></br>
                    <a href="https://github.com/tilak-io/tiplot">Link to GitHub</a>
                  </p>
                  <p className="m-0">
                    <ul>
      <li>Available on Windows and Linux.</li>
      <li>Able to load data from PX4, Ardupilot, DJI, CSV, Jupyter Notebook, etc. </li>
      <li>Its auto-completed dropdown menu makes it easy to browse through a long list of fields.</li>
      <li>Save, Load and share your layouts so collaborating has never been that easy.</li>
      <li>3D representation of the flight, add multiple 3D entities,etc makes TiPlot the perfect tool to review attitude and 3D trajectory of the aircraft.</li>
      <li>Needs more ? You can send data from your jupyter notebook or any python code, this open as many doors as you need !</li>
                      </ul>
                  </p>
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
      <a href="https://github.com/tilak-io/tiplot">
                <Image
                  src={require('./../../assets/images/tiplot.avif')}
                  alt="Features split 01"
                  />
      </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
