import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Our Achievements',
    paragraph:"At Tilak.io, we develop any type of software for any drones: DJI, PX4, Ardupilot, Proprietary, ..."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses} id="achievements">
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  New features in QGroundControl
                  </h3>
                  <p className="m-0 ta-l">
                    <ul>
                      <li>Custom terrain model and terrain avoidance (SRTM, ArcGIS WMTS, ...)</li>
                      <li>Flight Planning: Modify mission item / flight patterns (Orbit)</li>
                      <li>Add new instruments in fly view</li>
                      <li>Layout changes, add your brand</li>
                      <li>Create custom builds that benefit from mainline updates</li>
                    </ul>
                  </p>
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/tilakqgc.avif')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  Integrate with different DJI SDK
                  </h3>
                <p className="m-0 ta-l ">
                  <h5>DJI OSDK</h5>Log and Sync telemetry data from a DJI Matrice 300 RTK with your payload acquisition.
                  <h5>DJI MSDK</h5>Custom Android App to control DJI drones (Telemetry, Send orders, Flight plans, ...)
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/djisdk.avif')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  Customize PX4 Flight Controller
                  </h3>
                <p className="m-0 ta-l">
                    <ul>
                      <li>Custom flight modes</li>
                      <li>Geofences and Safety mechanisms</li>
                      <li>Alarms and fault detections</li>
                      <li>UAVCAN sensors and drivers</li>
                    </ul>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/_DSC0004.avif')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  Simulation
                  </h3>
                <p className="m-0 ta-l">
    Automatic test to validate new features. <br/>
    Investigate on incidents, bugs and reproduce it in simulation (SITL, MAVSdk, Gazebo) <br/>
    Improve simulation with new sensors, airframes, ... <br/>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/standard_vtol.avif')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12 text-color-primary">
                  Integrate Payload sensors
                  </h3>
                <p className="m-0 ta-l">
                  <ul>
                    <li><b>Control sensors</b> that are <b>incompatible</b> with common Flight Controller.</li>
                    <li>Code drivers on onboard computer. </li>
                    <li>Create API from ground application. </li>
                    <li>Synchronize drone telemetry with acquisition. </li>
                  </ul>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/drone_cube.avif')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                  <h4 className="text-color-warning" >Coming Soon !</h4>
                <h3 className="mt-0 mb-12 text-color-primary">
                  Fly DJI Drones with QGroundControl
                  </h3>
                <p className="m-0 ta-l">
                  We've been working on integrating DJI Drones into QGroundControl to benefit from a highly featured ground application together with the world largest drone manufacturer machines.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/qgcmapping.avif')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
